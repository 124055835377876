import { backendHost } from 'src/global';

type FetchElasticSearchProps = {
  body: RequestInit['body'];
  signal: RequestInit['signal'];
};

export const fetchElasticSearch = async ({
  body,
  signal,
}: FetchElasticSearchProps) => {
  return await fetch(`${backendHost}/facade/v4/search`, {
    signal,
    method: 'POST',
    headers: {
      'Content-type': 'application/json;charset=utf-8',
    },
    body: body,
  });
};
