import {
  ChevronDownSVG,
  ChevronRightSVG,
} from '@sbercloud/uikit-product-icons';
import { ButtonSimple } from '@snack-uikit/button';
import { Droplist, DroplistProps } from '@snack-uikit/list';
import { QuestionTooltip } from '@snack-uikit/tooltip';
import { Typography } from '@snack-uikit/typography';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { type MouseEvent, useState } from 'react';
import { useAnalytics } from 'src/hooks/useAnalytics';
import { type CatalogProduct } from 'src/types/backendContent';

import { getPlatformTooltip } from './getPlatformTooltip';
import s from './PlatformSelect.module.scss';
import { renderPlatformIcon } from './renderPlatformIcon';

const PLATFORMS_COMPARISON_URL = '/platforms-comparison';
const PLATFORMS_COMPARISON_TITLE = 'Сравнение платформ';

type ProductPlatform = CatalogProduct['productPlatforms'][number];

type PlatformSelectProps = {
  platforms: ProductPlatform[];
  value: string | undefined;
  onChange: (value: string) => void;
};

export function PlatformSelect({
  platforms,
  value,
  onChange,
}: PlatformSelectProps) {
  const router = useRouter();
  const { clickAnalytics } = useAnalytics();

  const [isOpen, setIsOpen] = useState(false);

  const items: DroplistProps['items'] = platforms.map((platform) => ({
    id: platform.slug,
    content: { option: platform.title },
    beforeContent: renderPlatformIcon(platform.slug),
    afterContent: platform.slug ? (
      <QuestionTooltip tip={getPlatformTooltip(platform.slug)} />
    ) : null,
  }));

  const itemsWithDivider: DroplistProps['items'] = [
    ...items,
    { type: 'group', divider: true, items: [] },
  ];

  const currentPlatform = platforms.find((platform) => platform.slug === value);

  const Icon = renderPlatformIcon(currentPlatform?.slug || '');

  const handleChange = (newValue: string) => {
    if (typeof newValue !== 'string') {
      return;
    }

    onChange(newValue);
  };

  const handleComparisonClick = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();

    clickAnalytics({
      action: PLATFORMS_COMPARISON_URL,
      clickZone: 'header',
      clickElement: 'textclick',
      clickContent: PLATFORMS_COMPARISON_TITLE,
      uniqueId: 'layout-header-platform-select-comparison',
      transitionType: 'inside-link',
    });

    router.push(PLATFORMS_COMPARISON_URL);
  };

  return (
    <Droplist
      trigger="click"
      items={itemsWithDivider}
      onOpenChange={setIsOpen}
      selection={{ value, onChange: handleChange, mode: 'single' }}
      footer={
        <ButtonSimple
          size="xs"
          label={PLATFORMS_COMPARISON_TITLE}
          href={PLATFORMS_COMPARISON_URL}
          onClick={handleComparisonClick}
          icon={<ChevronRightSVG />}
        />
      }
      closeDroplistOnItemClick
    >
      <div className={cn(s.root, { [s.rootFocus]: isOpen })} role="button">
        <div className={s.iconContainer}>{Icon}</div>
        <div className={s.value}>
          <Typography
            className={s.valueLabel}
            family="sans"
            purpose="body"
            size="s"
            tag="label"
          >
            Облачная платформа
          </Typography>
          <Typography
            className={s.valueTitle}
            family="sans"
            purpose="title"
            size="s"
            tag="div"
          >
            {currentPlatform?.title}
          </Typography>
        </div>
        <div className={s.iconChevronContainer}>
          <ChevronDownSVG className={s.iconChevron} />
        </div>
      </div>
    </Droplist>
  );
}
