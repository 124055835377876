import { Divider } from '@snack-uikit/divider';
import { type DroplistProps, List } from '@snack-uikit/list';
import cn from 'classnames';
import { type ProductCategory } from 'src/types/backendContent';

import s from './CategoryList.module.scss';

export type CategoryListProps = {
  categories: ProductCategory[];
  value: string | undefined;
  singleMode?: boolean;
  onChange: (value: string) => void;
  onOpenChange?: (value: boolean) => void;
  classNames?: {
    root?: string;
  };
};

export function CategoryList({
  categories,
  value,
  singleMode,
  onChange,
  classNames,
}: CategoryListProps) {
  const items: DroplistProps['items'] = categories.map(({ slug, title }) => ({
    id: slug,
    content: { option: title, truncate: { option: 2 } },
  }));

  const handleChange = (newValue: string) => {
    if (singleMode && (newValue === value || !newValue)) {
      return;
    } else {
      onChange(newValue);
    }
  };

  return (
    <div className={cn(s.root, classNames?.root)}>
      <Divider orientation="vertical" />
      <List
        size="m"
        items={items}
        selection={{ value, onChange: handleChange, mode: 'single' }}
        scroll
      />
    </div>
  );
}
