import { Avatar } from '@snack-uikit/avatar';
import { Typography } from '@snack-uikit/typography';
import cn from 'classnames';

import s from './UserInfo.module.scss';

interface UserInfoProps {
  userName: string;
  lastName: string;
  className?: string;
}

const UserInfo = ({ userName, lastName, className = '' }: UserInfoProps) => {
  return (
    <div className={cn(s.root, className)}>
      <Avatar
        name={`${userName} ${lastName}`}
        size="xs"
        showTwoSymbols
        appearance="green"
      />
      <Typography
        tag="div"
        size="s"
        purpose="body"
        family="sans"
        className={s.text}
      >
        {userName} {lastName}
      </Typography>
    </div>
  );
};

export { UserInfo };
