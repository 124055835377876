import { useCallback, useMemo, useState } from 'react';
import {
  type CatalogProduct,
  type ProductCategory,
} from 'src/types/backendContent';

type UseFilteredProductsProps = {
  categories: ProductCategory[];
  products: CatalogProduct[];
  initialPlatformFilter?: string;
  initialCategoryFilter?: string;
};

type UseFilteredProductsReturn = {
  /** Категории, отфильтрованные по платформе */
  categoriesByPlatform: ProductCategory[];
  /** Категории, отфильтрованные по продуктам */
  filteredCategories: ProductCategory[];
  /** Продукты, отфильтрованные по всем фильтрам */
  filteredProducts: CatalogProduct[];
  /** Slug платформы */
  platformFilter: string | undefined;
  /** Slug категории */
  categoryFilter: string | undefined;
  titleFilter: string;
  /** При вызове сбрасывает category и title фильтры */
  setPlatformFilter: (platformSlug: string | undefined) => void;
  /** При вызове сбрасывает title фильтр */
  setCategoryFilter: (categorySlug: string | undefined) => void;
  /** При вызове сбрасывает category фильтр */
  setTitleFilter: (title: string) => void;
};

export const useFilteredProducts = ({
  categories,
  products,
  initialPlatformFilter,
  initialCategoryFilter,
}: UseFilteredProductsProps): UseFilteredProductsReturn => {
  const [titleFilter, setTitleFilter] = useState<string>('');
  const [platformFilter, setPlatformFilter] = useState(initialPlatformFilter);
  const [categoryFilter, setCategoryFilter] = useState(initialCategoryFilter);

  const productsByPlatform = useMemo(
    () =>
      products.filter(
        (product) =>
          !platformFilter ||
          product.productPlatforms.some(
            (platform) => platform.slug === platformFilter,
          ),
      ),
    [platformFilter, products],
  );

  const categoriesByPlatform = useMemo(() => {
    if (!platformFilter) {
      return categories;
    }

    const categorySlugs = new Set<string>();

    for (const product of productsByPlatform) {
      for (const category of product.categories || []) {
        categorySlugs.add(category.slug);
      }
    }

    return categories.filter((category) => categorySlugs.has(category.slug));
  }, [categories, platformFilter, productsByPlatform]);

  const filteredProducts = useMemo(() => {
    return productsByPlatform.filter((item) => {
      const matchesCategory =
        !categoryFilter ||
        item.categories?.some((category) => category.slug === categoryFilter);

      const matchesTitle = item.title
        .toLowerCase()
        .includes(titleFilter.trim().toLowerCase());

      const matchesDescription = item.description
        ?.toLowerCase()
        .includes(titleFilter.trim().toLowerCase());

      return matchesCategory && (matchesTitle || matchesDescription);
    });
  }, [productsByPlatform, categoryFilter, titleFilter]);

  const filteredCategories = useMemo(() => {
    const categorySlugs = new Set<string>();

    for (const product of filteredProducts) {
      for (const category of product.categories || []) {
        if (!categoryFilter || category.slug === categoryFilter) {
          categorySlugs.add(category.slug);
        }
      }
    }

    return categories.filter((category) => categorySlugs.has(category.slug));
  }, [categories, categoryFilter, filteredProducts]);

  const changePlatformFilter = useCallback(
    (platformSlug: string | undefined) => {
      setTitleFilter('');
      setCategoryFilter(initialCategoryFilter);
      setPlatformFilter(platformSlug);
    },
    [initialCategoryFilter],
  );

  const changeCategoryFilter = useCallback(
    (categorySlug: string | undefined) => {
      setTitleFilter('');
      setCategoryFilter(categorySlug);
    },
    [],
  );

  const changeTitleFilter = useCallback(
    (title: string) => {
      if (!title.trim()) {
        setCategoryFilter(initialCategoryFilter);
      } else {
        setCategoryFilter(undefined);
      }

      setTitleFilter(title);
    },
    [initialCategoryFilter],
  );

  return {
    categoriesByPlatform,
    filteredCategories,
    filteredProducts,
    platformFilter,
    categoryFilter,
    titleFilter,
    setPlatformFilter: changePlatformFilter,
    setCategoryFilter: changeCategoryFilter,
    setTitleFilter: changeTitleFilter,
  };
};
