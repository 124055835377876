import { SearchSVG } from '@sbercloud/uikit-product-icons';
import { ButtonSimple } from '@snack-uikit/button';
import cn from 'classnames';
import { useAnalytics } from 'src/hooks/useAnalytics';
import { useWindowWidth } from 'src/hooks/useWindowWidth';

import s from './HeaderSearchInput.module.scss';

export function HeaderSearchInput({
  onClick,
  active,
}: {
  onClick(): void;
  active?: boolean;
}) {
  const { clickAnalytics } = useAnalytics();

  const {
    windowWidth,
    breakpoints: { MD },
  } = useWindowWidth();

  return (
    <ButtonSimple
      className={cn(s.root, {
        [s.active]: !!active,
        [s.adaptive]: windowWidth && windowWidth < MD,
      })}
      appearance="neutral"
      icon={<SearchSVG size={24} />}
      onClick={() => {
        onClick();
        clickAnalytics({
          action: 'click',
          clickZone: 'header',
          clickElement: 'textclick',
          clickContent: 'Поиск',
          uniqueId: `header-search-input-click`,
          transitionType: 'inside-link',
        });
      }}
    />
  );
}
