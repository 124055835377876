import { ChevronRightSVG } from '@sbercloud/uikit-product-icons';
import { Typography } from '@snack-uikit/typography';
import cn from 'classnames';
import { Fragment } from 'react';
import { useAnalytics } from 'src/hooks/useAnalytics';
import { useWindowWidth } from 'src/hooks/useWindowWidth';
import { Link } from 'src/uikit/Link';

import { ListGridType, ListType } from '../../types';
import { HeaderCardProduct } from '../HeaderCardProduct';
import { OfferCard } from '../OfferCard';
import { PlatformCard } from '../PlatformCard';
import s from './List.module.scss';

export function List({
  list,
  gridColumns = 'three',
  onClickForClose,
  sectionTitle,
}: {
  list: ListType;
  onClickForClose(): void;
  gridColumns?: ListGridType;
  sectionTitle?: string;
}) {
  const { clickAnalytics } = useAnalytics();
  const { windowWidth } = useWindowWidth();
  const HEADER_DESKTOP_WIDTH = 1269;

  return (
    <div className={s.root}>
      {windowWidth && windowWidth > HEADER_DESKTOP_WIDTH ? (
        <div className={s.sectionGrid}>
          {list.map(({ title, subtitle, items }, index) => (
            <Fragment key={`${title}_${index}`}>
              <div className={cn(s.grid, s[gridColumns])}>
                {title && (
                  <div className={cn(s.header, s[gridColumns])}>
                    {title && (
                      <Typography
                        tag="h2"
                        size="l"
                        family="sans"
                        purpose="title"
                        className={s.title}
                      >
                        {title}
                      </Typography>
                    )}
                    {subtitle && subtitle?.link ? (
                      <Link href={subtitle?.link} className={s.subtitleLink}>
                        <Typography
                          tag="span"
                          size="l"
                          family="sans"
                          purpose="label"
                        >
                          {subtitle?.title}
                        </Typography>
                        <ChevronRightSVG size={24} />
                      </Link>
                    ) : (
                      <Typography
                        tag="div"
                        size="l"
                        family="sans"
                        purpose="label"
                        className={s.subtitle}
                      >
                        {subtitle?.title}
                      </Typography>
                    )}
                  </div>
                )}
                {items.map((item, index) => {
                  if ('type' in item && item.type === 'offers') {
                    return (
                      <OfferCard
                        key={index}
                        onClickForClose={onClickForClose}
                        {...item}
                      />
                    );
                  }

                  if ('type' in item && item.type === 'platform') {
                    return (
                      <PlatformCard
                        key={index}
                        className={s.cardEnd}
                        {...item}
                      />
                    );
                  }

                  return (
                    <HeaderCardProduct
                      key={index}
                      {...item}
                      onClickForClose={() => {
                        clickAnalytics({
                          action: item.link,
                          clickZone: 'header',
                          clickElement: 'cardclick',
                          clickContent: item.title,
                          uniqueId: `header-menu-section-${sectionTitle?.toUpperCase()}-card-${item.title.toUpperCase()}`,
                          transitionType: 'inside-link',
                        });
                        onClickForClose && onClickForClose();
                      }}
                    />
                  );
                })}
              </div>
            </Fragment>
          ))}
        </div>
      ) : (
        <>
          {list.map(({ title, subtitle, items }, index) => {
            return (
              <div className={s.col} key={index}>
                {title && (
                  <div className={cn(s.header, s[gridColumns])}>
                    {title && (
                      <Typography
                        tag="h2"
                        size="m"
                        family="sans"
                        purpose="title"
                        className={s.title}
                      >
                        {title}
                      </Typography>
                    )}
                    {subtitle && subtitle?.link ? (
                      <Link href={subtitle?.link} className={s.subtitleLink}>
                        <Typography
                          tag="span"
                          size="l"
                          family="sans"
                          purpose="label"
                        >
                          {subtitle?.title}
                        </Typography>
                        <ChevronRightSVG size={24} />
                      </Link>
                    ) : (
                      <Typography
                        tag="div"
                        size="l"
                        family="sans"
                        purpose="label"
                        className={s.subtitle}
                      >
                        {subtitle?.title}
                      </Typography>
                    )}
                  </div>
                )}
                <div className={s.items}>
                  {items.map((item, index) => {
                    if ('type' in item && item.type === 'offers') {
                      return (
                        <OfferCard
                          key={index}
                          onClickForClose={onClickForClose}
                          {...item}
                        />
                      );
                    }

                    if ('type' in item && item.type === 'platform') {
                      return (
                        <PlatformCard
                          key={index}
                          className={s.cardEnd}
                          {...item}
                        />
                      );
                    }
                    return <HeaderCardProduct key={index} {...item} />;
                  })}
                </div>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
}
