import { Typography } from '@snack-uikit/typography';
import cn from 'classnames';
import { SocialButtons } from 'src/components/SocialButtons';

import s from './Footer.module.scss';
import { BOTTOM_LINKS } from './mock';
import { renderFooterLink } from './renderFooterLink';

export function FooterBottom() {
  return (
    <div className={s.footerBottom}>
      <div className={s.footerBottomItem}>
        <Typography
          className={s.footerCopyright}
          family="sans"
          purpose="body"
          size="m"
        >
          &copy;&nbsp;{new Date().getFullYear()}&nbsp;Cloud.ru
        </Typography>
      </div>
      <div className={cn(s.footerBottomItem, s.footerBottomLinks)}>
        {BOTTOM_LINKS.map(renderFooterLink)}
      </div>
      <div className={s.footerBottomItem}>
        <SocialButtons classNameSocialButton={s.footerBottomSocialButton} />
      </div>
    </div>
  );
}
