import { CrossSVG, SearchSVG } from '@sbercloud/uikit-product-icons';
import { ButtonFilled, ButtonFunction } from '@snack-uikit/button';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useAnalytics } from 'src/hooks/useAnalytics';

import s from './HeaderSearch.module.scss';

export function HeaderSearch({
  onCloseSearch,
  onKeyBoardCloseSearch,
}: {
  onCloseSearch(): void;
  onKeyBoardCloseSearch(event: React.KeyboardEvent): void;
}) {
  const { funnelFormInput, searchAnalytics, clickAnalytics } = useAnalytics();

  const [inputValue, setInputValue] = useState('');

  const router = useRouter();

  const handleSubmitButton = (event: React.KeyboardEvent) => {
    if (inputValue.trim().length >= 2) {
      if (event.key === 'Enter') {
        handleRouterPush(inputValue);
      }
    }
  };

  const handleRouterPush = (input: string) => {
    const encodedValue = encodeURIComponent(input.trim());
    searchAnalytics('header', input);
    router.push(`/search?query=${encodedValue}`);
  };

  const clearBtnClick = () => {
    setInputValue('');
  };

  return (
    <div
      onKeyDown={(event: React.KeyboardEvent) => onKeyBoardCloseSearch(event)}
      tabIndex={1}
    >
      <div className={s.searchWrapper}>
        <div className={s.inputWrapper}>
          <SearchSVG size={24} className={s.iconSearch} />
          <input
            className={s.field}
            placeholder="Поиск"
            value={inputValue ?? ''}
            onChange={(event) => setInputValue(event.target.value)}
            onClick={funnelFormInput}
            onKeyDown={handleSubmitButton}
            maxLength={180}
            autoFocus
          />
          {inputValue.trim().length > 1 && (
            <div className={s.searchActions}>
              <CrossSVG
                onClick={clearBtnClick}
                size={24}
                className={s.buttonClear}
              />
              <div className={s.separate} />
              <ButtonFilled
                className={s.sendingButton}
                label="Найти"
                appearance="neutral"
                size="s"
                onClick={() => {
                  handleRouterPush(inputValue);

                  clickAnalytics({
                    action: 'click',
                    clickZone: 'body',
                    clickElement: 'button',
                    clickContent: 'Найти',
                    uniqueId: `search-header-button-send-query`,
                    transitionType: 'inside-link',
                  });
                }}
              />
            </div>
          )}
        </div>
        <ButtonFunction
          size="m"
          label="Закрыть"
          data-qa="next_step_button"
          className={s.buttonClose}
          onClick={() => {
            onCloseSearch();
            clickAnalytics({
              action: 'click',
              clickZone: 'body',
              clickElement: 'button',
              clickContent: 'Закрыть',
              uniqueId: `search-page-button-close-input`,
              transitionType: 'inside-link',
            });
          }}
        />
      </div>
    </div>
  );
}
