import {
  AdvancedSVG,
  ChevronRightSVG,
  CloudMoveSVG,
  CloudSVG,
  DataSVG,
  DetalisationSVG,
  EditSVG,
  EducationSVG,
  EvolutionSVG,
  FileSVG,
  FireSVG,
  LaptopSVG,
  MlSpaceSVG,
  PlaySVG,
  RocketSVG,
  RubleSVG,
  SearchSVG,
  SecuritySVG,
  StarsMagicSVG,
  VmwareSVG,
} from '@sbercloud/uikit-product-icons';

import { ContentType, MenuItemsType } from './types';

export const MENU_ITEMS: MenuItemsType = [
  {
    id: '0',
    title: 'Продукты',
    type: 'catalog' as ContentType,
  },
  {
    id: '1',
    title: 'Цены',
    type: 'list' as ContentType,
    list: [
      {
        title: 'Полезные разделы',
        items: [
          {
            title: 'Калькулятор цен',
            text: 'Соберите свою конфигурацию ресурсов, узнайте ее стоимость и подключите',
            link: '/calculator',
            icon: <RubleSVG size={24} />,
            type: 'default',
          },
          {
            title: 'Тарифы',
            text: 'Тарифы на услуги и сервисы платформ',
            link: '/documents/tariffs/index.html',
            icon: <DetalisationSVG size={24} />,
            type: 'default',
          },
        ],
      },
      {
        title: 'Акции',
        subtitle: {
          title: 'Все акции',
          link: '/offers',
          icon: <ChevronRightSVG size={24} />,
        },
        items: [
          {
            title: 'Дарим до 20 000 бонусов ',
            link: '/offers/free-test',
            icon: 'https://cdn.cloud.ru/backend/carousel/main-carousel/bonuses.png',
            theme: '#99c1ff',
            type: 'offers',
          },
          {
            title: 'Evolution free tier',
            link: '/offers/free-tier',
            icon: 'https://cdn.cloud.ru/backend/carousel/main-carousel/main_slide_2.png',
            theme: '#26d07c',
            type: 'offers',
          },
          {
            title: 'Мигрируйте бесплатно в&nbsp;наше облако',
            link: '/offers/migration',
            icon: 'https://cdn.cloud.ru/backend/carousel/main-carousel/main_slide_1.png',
            theme: '#e1d7f2',
            type: 'offers',
          },
        ],
      },
    ],
  },
  {
    id: '2',
    title: 'Документация',
    type: 'link' as ContentType,
    link: '/docs/index.html',
    target: '_blank',
  },
  {
    id: '3',
    title: 'О компании',
    type: 'list' as ContentType,
    list: [
      {
        items: [
          {
            title: 'О нас',
            text: 'Профессиональная поддержка ваших бизнес-задач',
            link: '/about',
            icon: <CloudSVG size={24} />,
            type: 'default',
          },
          {
            title: 'Карьера в Cloud.ru',
            text: 'Станьте частью команды',
            link: '/career',
            target: '_blank',
            icon: <RocketSVG size={24} />,
            type: 'default',
          },
          {
            title: 'Новости',
            text: 'Важные события и объявления',
            link: '/news',
            icon: <DataSVG size={24} />,
            type: 'default',
          },
          {
            title: 'Юридические документы',
            text: 'Договоры, оферта, условия и другое',
            link: '/documents/',
            icon: <FileSVG size={24} />,
            type: 'default',
          },
          {
            title: 'Контакты',
            text: 'Адрес офиса и способы связи с нами',
            link: '/contacts',
            icon: <EditSVG size={24} />,
            type: 'default',
          },
        ],
      },
    ],
  },
  {
    id: '4',
    title: 'Для бизнеса',
    type: 'list' as ContentType,
    gridColumns: 'three',
    list: [
      {
        title: 'Полезные разделы',
        items: [
          {
            title: 'Партнерство с Cloud.ru',
            text: 'Максимальный заработок и расширение вашего бизнеса',
            link: '/partners',
            icon: <CloudSVG size={24} />,
            type: 'default',
          },
          {
            title: 'Кейсы',
            text: 'Как компании создают и развивают бизнес в облаке Cloud.ru',
            link: '/cases',
            icon: <FireSVG size={24} />,
            type: 'default',
          },
          {
            title: 'Решения',
            text: 'Готовые решения для бизнеса, разработки и других задач',
            link: '/solutions',
            icon: <StarsMagicSVG size={24} />,
            type: 'default',
          },
          {
            title: 'Архитектурный центр',
            text: 'Лучшие практики перехода в облако Cloud.ru',
            link: '/docs/architecture-center/index.html',
            icon: <CloudMoveSVG size={24} />,
            type: 'default',
          },
          {
            title: 'Безопасность',
            text: 'Обеспечиваем высокий уровень безопасности клиентских данных',
            link: '/security',
            icon: <SecuritySVG size={24} />,
            type: 'default',
          },
        ],
      },
      {
        title: 'Облачные платформы Cloud.ru',
        items: [
          {
            title: 'Evolution',
            text: 'Публичное российское облако на базе open source',
            link: '/evolution',
            icon: <EvolutionSVG size={24} />,
            type: 'default',
            tags: [
              {
                title: 'Доступна всем',
                appearance: 'green',
              },
            ],
          },
          {
            title: 'Advanced',
            text: '60+ IaaS- и PaaS-сервисов для развития инфраструктуры',
            link: '/advanced',
            icon: <AdvancedSVG size={24} />,
            type: 'default',
          },
          {
            title: 'ML Space',
            text: 'Суперкомпьютеры и ML для создания приложений',
            link: '/mlspace',
            icon: <MlSpaceSVG size={24} />,
            type: 'default',
            tags: [
              {
                title: 'Только для юрлиц',
                appearance: 'violet',
              },
            ],
          },
          {
            title: 'Облако VMware',
            text: 'Облачная инфраструктура на базе технологий VMware',
            link: '/vmware',
            icon: <VmwareSVG size={24} />,
            type: 'default',
            tags: [
              {
                title: 'Только для юрлиц',
                appearance: 'violet',
              },
            ],
          },
          {
            title: 'В чем отличия платформ?',
            text: `Выберите подходящую облачную платформу под ваши задачи и требования.`,
            link: '/platforms-comparison',
            linkText: 'Сравнение платформ',
            theme: '#EEF4FD',
            type: 'platform',
            clickzone: 'header',
          },
        ],
      },
    ],
  },
  {
    id: '5',
    title: 'Сообщество',
    type: 'list' as ContentType,
    gridColumns: 'four',
    list: [
      {
        items: [
          {
            title: 'Блог',
            text: 'Всё про облачные технологии и AI c пользой для бизнеса',
            link: '/blog',
            icon: <LaptopSVG size={24} />,
            type: 'default',
          },
          {
            title: 'Обучение и сертификация',
            text: 'Развивайтесь в сфере облачных технологий и подтверждайте опыт',
            link: '/education',
            icon: <EducationSVG size={24} />,
            type: 'default',
          },
          {
            title: 'Мероприятия',
            text: 'Анонсы событий и записи вебинаров',
            link: '/events',
            icon: <PlaySVG size={24} />,
            type: 'default',
          },
          {
            title: 'Исследования Cloud.ru',
            text: 'Участвуйте в исследовании сервисов Cloud.ru',
            link: '/issledovanie-oblachnye-tehnologii',
            icon: <SearchSVG size={24} />,
            type: 'default',
          },
        ],
      },
    ],
  },
];
