import {
  AdvancedSVG,
  CloudSVG,
  EvolutionSVG,
  MlSpaceSVG,
  VmwareSVG,
} from '@sbercloud/uikit-product-icons';
import { type ReactNode } from 'react';

const PLATFORM_ICON_MAP: Record<string, ReactNode> = {
  evolution: <EvolutionSVG />,
  advanced: <AdvancedSVG />,
  mlspace: <MlSpaceSVG />,
  vmware: <VmwareSVG />,
};

export const renderPlatformIcon = (slug: string): ReactNode =>
  PLATFORM_ICON_MAP[slug] || <CloudSVG />;
