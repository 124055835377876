import { Typography } from '@snack-uikit/typography';
import cn from 'classnames';

import s from './Footer.module.scss';
import { type FooterLink, renderFooterLink } from './renderFooterLink';

type LinksBlock = {
  title: string;
  links: FooterLink[];
};

const renderLinksBlock = ({ title, links }: LinksBlock, i: number) => (
  <div key={String(i)}>
    <Typography
      className={s.footerTitle}
      family="sans"
      purpose="label"
      tag="h3"
      size="l"
    >
      {title}
    </Typography>
    <ul className={s.footerLinks}>{links.map(renderFooterLink)}</ul>
  </div>
);

export type FooterBlock = { blocks: LinksBlock[] };

const renderFooterBlock = (block: FooterBlock, i: number) => {
  return (
    <div key={String(i)} className={s.footerBlock}>
      {block.blocks.map(renderLinksBlock)}
    </div>
  );
};

type FooterBodyProps = {
  className?: string;
  blocks: FooterBlock[];
};

export function FooterBody({ className, blocks }: FooterBodyProps) {
  return (
    <div className={cn(s.footerBody, className)}>
      {blocks.map(renderFooterBlock)}
    </div>
  );
}
