import { Typography } from '@snack-uikit/typography';
import { Link } from 'src/uikit/Link';
import { genStringForUrl as transliteration } from 'src/utils/genStringForUrl';

import s from './Footer.module.scss';

export type FooterLink = {
  text: string;
  url: string;
  target?: '_self' | '_blank';
};

export const renderFooterLink = ({ url, text, target }: FooterLink) => {
  const id = `layout-footer-link-${transliteration(text)}`;

  return (
    <li className={s.footerLinkWrapper} key={id}>
      <Link
        data-qa={id}
        className={s.footerLink}
        href={url}
        target={target}
        analyticsOptions={{
          action: url,
          clickZone: 'footer',
          clickElement: 'textclick',
          clickContent: text,
          uniqueId: id,
          transitionType: 'inside-link',
        }}
      >
        <Typography
          className={s.footerLinkText}
          family="sans"
          purpose="body"
          size="m"
        >
          {text}
        </Typography>
      </Link>
    </li>
  );
};
