import { Logo } from 'src/components/Logo';
import { Link } from 'src/uikit/Link';

import s from './Footer.module.scss';
import { LocaleSwitcher } from './LocaleSwitcher';

export function FooterTop() {
  return (
    <div className={s.footerTop}>
      <Link
        className={s.footerLogoLink}
        href="/"
        data-qa="footer-logo"
        id="layout-footer-logo-link"
        analyticsOptions={{
          action: '/',
          clickZone: 'footer',
          clickElement: 'textclick',
          clickContent: 'Logo',
          uniqueId: 'layout-footer-logo-link',
          transitionType: 'inside-link',
        }}
      >
        <Logo className={s.footerLogo} />
      </Link>
      <LocaleSwitcher />
    </div>
  );
}
