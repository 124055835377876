import { ExitSVG } from '@sbercloud/uikit-product-icons';
import { ButtonFunction } from '@snack-uikit/button';
import { Typography } from '@snack-uikit/typography';
import cn from 'classnames';
import { useAnalytics } from 'src/hooks/useAnalytics';

import s from './ExitButton.module.scss';

const ExitButton = ({
  uniqueId,
  label,
  className = '',
}: {
  uniqueId: string;
  label?: string;
  className?: string;
}) => {
  // const { signoutRedirect } = useAuth();
  const { clickAnalytics } = useAnalytics();

  return (
    <div
      className={s.buttonExitWrapper}
      onClick={() => {
        clickAnalytics({
          action: 'click',
          clickZone: 'header',
          clickElement: 'cardclick',
          clickContent: label || 'Выйти из аккаунта',
          uniqueId: uniqueId,
          transitionType: 'inside-link',
        });
        // terminateSession(signoutRedirect);
      }}
    >
      <ButtonFunction
        label=""
        size="xs"
        appearance="neutral"
        icon={<ExitSVG size={24} />}
        iconPosition="before"
        className={cn(s.buttonExit, className)}
      />
      {label && (
        <Typography
          tag="span"
          size="s"
          purpose="body"
          family="sans"
          className={s.buttonExitText}
        >
          {label}
        </Typography>
      )}
    </div>
  );
};

export { ExitButton };
