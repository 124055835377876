import { Tag } from '@snack-uikit/tag';
import { Typography } from '@snack-uikit/typography';
import { ReactNode } from 'react';
import { Link } from 'src/uikit/Link';

import { TagType } from '../../types';
import s from './HeaderCardProduct.module.scss';

const DEFAULT_ICON = '/svg/components/ProductsCatalog/icon.svg';

interface HeaderCardProductProps {
  title: string;
  text?: string;
  link: string;
  icon: string | ReactNode;
  tags?: TagType[];
  target?: '_blank' | '_self';
  onClickForClose?(): void;
}

export function HeaderCardProduct({
  title,
  text,
  link,
  tags,
  icon,
  target = '_self',
  onClickForClose,
}: HeaderCardProductProps) {
  return (
    <div className={s.root} onClick={onClickForClose}>
      <div className={s.icon}>
        {typeof icon === 'string' ? (
          <img src={icon || DEFAULT_ICON} width={40} height={40} alt={title} />
        ) : (
          icon
        )}
      </div>
      <div className={s.content}>
        <div className={s.header}>
          <div className={s.title}>
            <Link className={s.link} href={link} target={target}>
              <Typography
                tag="span"
                size="l"
                purpose="label"
                family="sans"
                className={s.titleText}
              >
                <span dangerouslySetInnerHTML={{ __html: title }} />
              </Typography>
            </Link>
          </div>

          {tags &&
            tags.length > 0 &&
            tags.map(({ title, appearance }, index) => {
              return (
                <Tag
                  key={`${title}_${index}`}
                  label={title}
                  size="xs"
                  appearance={appearance}
                />
              );
            })}
        </div>
        {text && (
          <div className={s.description}>
            <Typography tag="div" size="m" purpose="body" family="sans">
              {text}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
}
