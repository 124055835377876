import { CatalogProduct, type ProductPlatform } from 'src/types/backendContent';

export const PLATFORMS: CatalogProduct['productPlatforms'] = [
  {
    id: 'id-evolution',
    title: 'Evolution' as ProductPlatform,
    slug: 'evolution',
  },
  {
    id: 'id-advanced',
    title: 'Advanced' as ProductPlatform,
    slug: 'advanced',
  },
  {
    id: 'id-mlspace',
    title: 'ML Space' as ProductPlatform,
    slug: 'mlspace',
  },
  {
    id: 'id-vmware',
    title: 'Облако VMware' as ProductPlatform,
    slug: 'vmware',
  },
];
