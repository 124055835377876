import cn from 'classnames';
import Image from 'next/image';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button as ButtonOld } from 'src/components/Button';
import {
  SuccessfullSubmissionFormAnalyticsType,
  useAnalytics,
} from 'src/hooks/useAnalytics';
import { useGtmDataLayer } from 'src/hooks/useGtmDataLayer';
import { useIntersectionObserveForms } from 'src/hooks/useIntersectionObserverForms';
import { useSubmitForm } from 'src/hooks/useSubmitForm';
import { useTryFormErrorsCheck } from 'src/hooks/useTryFormErrorsCheck';
import { PortalModal } from 'src/layout/PortalModal';
import { DataFromFormInputs, FormStatus } from 'src/types';
import { Button } from 'src/uikit/Button';
import { Checkbox } from 'src/uikit/Form/Checkbox';
import { Input } from 'src/uikit/Form/Input';
import { InputType, ValidationType } from 'src/uikit/Form/Input/constants';

import { MessageErrorOnFormSubmit, ShadowInput } from '../FormNew';
import s from './Subscribe.module.scss';

enum NoticeStatuses {
  Success = 'Спасибо, что подписались на нашу рассылку',
  Error = 'Что-то пошло не так, форма не отправлена',
}

function SubscribeNotice({
  isShowNotice,
  setIsShowNotice,
  formStatus,
  setFormStatus,
}: {
  isShowNotice: boolean;
  setIsShowNotice: React.Dispatch<React.SetStateAction<boolean>>;
  formStatus: FormStatus;
  setFormStatus: React.Dispatch<React.SetStateAction<FormStatus>>;
}) {
  const buttonTitle = 'попробовать еще раз';
  return (
    <div
      className={cn(s.notice, {
        [s.hide]: formStatus !== 'success' && formStatus !== 'error',
      })}
    >
      {formStatus === 'success' && (
        <h2 className={s.noticeTitle}>{NoticeStatuses.Success}</h2>
      )}
      {formStatus === 'error' && (
        <>
          <h2 className={s.noticeTitle}>{NoticeStatuses.Error}</h2>
          <Button
            className={s.noticeButton}
            onClick={() => {
              setFormStatus('idle');
              setIsShowNotice(false);
            }}
            type="reset"
            analyticsOptions={{
              action: 'click',
              clickZone: 'footer',
              clickElement: 'button',
              clickContent: buttonTitle,
              uniqueId: 'subscribe-notice-button-try-again',
              transitionType: 'inside-link',
            }}
          >
            {buttonTitle}
          </Button>
        </>
      )}
    </div>
  );
}

function SubscribeContent({
  eventLabel,
  onSubmitForm,
  className,
  classNameTitle,
  wrapperClassName = '',
  errorClassName = '',
  inputClassName = '',
  title,
  description,
  footnote,
  isCustomNotification,
  isColumnText,
  isModal,
  successfulSubmissonFormAnalytics,
}: {
  eventLabel: string;
  onSubmitForm: () => void;
  className?: string;
  wrapperClassName?: string;
  classNameTitle?: string;
  title: string;
  errorClassName?: string;
  inputClassName?: string;
  description?: string;
  footnote?: string;
  isCustomNotification?: boolean;
  isColumnText?: boolean;
  isModal: boolean;
  successfulSubmissonFormAnalytics?: SuccessfullSubmissionFormAnalyticsType;
}) {
  const [errorMessage, setErrorMessage] = useState<boolean>(false);
  const [isShowNotice, setIsShowNotice] = useState(false);

  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors, isSubmitting },
    reset,
    resetField,
  } = useForm<DataFromFormInputs>({
    mode: 'onChange',
    defaultValues: {
      subscribeForMailingSub: false,
    },
  });
  const { sendDataToDataLayer } = useGtmDataLayer();
  const { submitForm, setFormStatus, formStatus, formMessage, resultCode } =
    useSubmitForm();
  const {
    tryFormAnalytics,
    digestFormAnalytics,
    successfullSubmissionFormAnalytics,
  } = useAnalytics();

  const { formRef } = useIntersectionObserveForms();

  useTryFormErrorsCheck(errors, isSubmitting, 'subscription');

  const onSubmit = async (data: DataFromFormInputs) => {
    const dataLayerToSend = {
      dataLayer: {
        event: 'custom_event',
        event_category: 'formSubscribe',
        event_action: 'formSuccess',
        event_label: eventLabel,
      },
    };

    await submitForm({
      backendPatchInput: '/forms/v1/subscribe',
      allFormSentFormType: 'subscription',
      allFormSentIsPopup: isModal,
      dataFromFormInputs: {
        ...data,
        subscribeForMailing: true,
        other_consents: ['site_services_consent'],
      },
      onSuccess: () => {
        tryFormAnalytics({ formType: 'subscription', action: 'Отправлено' });
        reset();
        sendDataToDataLayer(dataLayerToSend);
        digestFormAnalytics();
        onSubmitForm();
        successfulSubmissonFormAnalytics &&
          successfullSubmissionFormAnalytics(successfulSubmissonFormAnalytics);
      },
      onError: () => {
        tryFormAnalytics({
          formType: 'subscription',
          action: 'Ошибка',
          errorCode: resultCode,
        });
        setErrorMessage(true);
      },
    });
  };

  useEffect(() => {
    // resultCode === 03002 - email already exist
    if (
      (formStatus === 'error' && resultCode !== '03002') ||
      formStatus === 'success'
    ) {
      setIsShowNotice(true);
    } else {
      setIsShowNotice(false);
    }
  }, [resultCode, formStatus]);

  return (
    <div className={cn(s.root, className)}>
      <div
        className={cn(s.wrapper, wrapperClassName, {
          [s.hidden]:
            isCustomNotification && (errorMessage || formStatus === 'success'),
        })}
        ref={formRef}
      >
        <div className={cn(s.content, { [s.contentColumn]: isColumnText })}>
          <div
            className={cn(s.title, classNameTitle)}
            data-qa="subscribe_form_title"
          >
            {title}
          </div>
          {description && (
            <div className={s.text} data-qa="subscribe_form_text">
              {description}
            </div>
          )}
        </div>
        <form
          className={s.form}
          onSubmit={handleSubmit(onSubmit)}
          data-qa="subscribe_form"
        >
          <div className={s.group}>
            <div className={s.inputWrapper}>
              <Input
                required
                label="Электронная почта"
                validationType={ValidationType.Email}
                inputType={InputType.Text}
                name="email"
                errors={errors}
                resetField={resetField}
                register={register}
                onBlur={() => {}}
              />
              {!isShowNotice &&
                formStatus === 'error' &&
                !isCustomNotification && (
                  <MessageErrorOnFormSubmit text={formMessage} />
                )}
            </div>
            <ShadowInput register={register} />
            <Button
              onClick={() => trigger()}
              isDisabled={formStatus === 'sending'}
              data-qa="subscribe_warp"
              className={cn(s.button, s.submit, 'gtm-click-subscribe-form')}
              analyticsOptions={{
                action: 'click',
                clickZone: 'footer',
                clickElement: 'button',
                clickContent: 'Подписаться',
                uniqueId: 'subscribe-form-button',
                transitionType: 'inside-link',
              }}
            >
              Подписаться
            </Button>
          </div>
          {footnote ? (
            <div
              className={s.footnote}
              dangerouslySetInnerHTML={{ __html: footnote }}
            />
          ) : (
            <Checkbox
              name="subscribeForMailingSub"
              className={s.checkbox}
              value="true"
              label="Я даю <a href='https://cdn.cloud.ru/backend/docs/security/consent_advertising.pdf'>согласие</a> на обработку моих персональных данных и получение рекламных и информационных сообщений в соответствие условиями <a target='_blank' href='https://cdn.cloud.ru/backend/docs/security/politic.pdf'>политики конфиденциальности</a>"
              errors={errors}
              register={register}
              fullWidth={false}
              required
            />
          )}
        </form>

        {!isCustomNotification && isShowNotice && (
          <SubscribeNotice
            formStatus={formStatus}
            setFormStatus={setFormStatus}
            isShowNotice={isShowNotice}
            setIsShowNotice={setIsShowNotice}
          />
        )}
      </div>

      {isCustomNotification && errorMessage && (
        <div className={cn(s.wrapper, s.wrapperNotification)}>
          <div className={s.notification}>
            <div className={s.notificationContent}>
              <div className={s.notificationTitle}>{formMessage}</div>
              <ButtonOld
                className={s.notificationButton}
                onClick={() => setErrorMessage(false)}
                id="subscribe-notification-button"
                analyticsOptions={{
                  action: 'click',
                  clickZone: 'footer',
                  clickElement: 'button',
                  clickContent: 'Попробовать еще раз',
                  uniqueId: 'subscribe-notification-button',
                  transitionType: 'inside-link',
                }}
              >
                Попробовать еще раз
              </ButtonOld>
            </div>
          </div>
        </div>
      )}

      {isCustomNotification && formStatus === 'success' && (
        <div className={cn(s.wrapper, s.wrapperNotification)}>
          <div className={s.notification}>
            <div className={s.notificationContent}>
              <div className={s.notificationTitle}>Спасибо за подписку!</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

interface SubscribeProps {
  variant: any;
  eventLabel: string;
  title: string;
  description?: string;
  className?: string;
  classNameTitle?: string;
  wrapperClassName?: string;
  onSubmitForm?: () => void;
  isModalOpen?: any;
  setIsModalOpen?: any;
  footnote?: string;
  isCustomNotification?: boolean;
  isColumnText?: boolean;
  successfulSubmissonFormAnalytics?: SuccessfullSubmissionFormAnalyticsType;
}

export function Subscribe({
  variant,
  isModalOpen,
  setIsModalOpen,
  onSubmitForm,
  eventLabel,
  title,
  description,
  footnote,
  className = '',
  classNameTitle = '',
  wrapperClassName = '',
  isCustomNotification,
  isColumnText,
  successfulSubmissonFormAnalytics,
}: SubscribeProps) {
  const handleSubmitForm = () => {
    onSubmitForm && onSubmitForm();
  };

  return (
    <>
      {variant !== 'modal' && (
        <SubscribeContent
          className={cn(className, {
            [s.dark]: variant === 'dark',
          })}
          classNameTitle={classNameTitle}
          wrapperClassName={wrapperClassName}
          errorClassName={s.error}
          inputClassName={s.input}
          eventLabel={eventLabel}
          onSubmitForm={handleSubmitForm}
          title={title}
          description={description}
          footnote={footnote}
          isCustomNotification={isCustomNotification}
          isColumnText={isColumnText}
          isModal={false}
          successfulSubmissonFormAnalytics={
            successfulSubmissonFormAnalytics && {
              formname: successfulSubmissonFormAnalytics.formname,
              zoneclick: 'B',
            }
          }
        />
      )}
      {isModalOpen && (
        <PortalModal
          onClickForClose={() => setIsModalOpen(false)}
          withoutDefaultCloseIcon
          className={s.modal}
          portalClassName={s.portal}
        >
          <div className={s.modalWrapper}>
            <Image
              src="/svg/pages/warp/article/close-icon.svg"
              alt="Close icon"
              className={s.close}
              data-qa="close icon"
              onClick={() => setIsModalOpen(false)}
              width={24}
              height={24}
            />
            <SubscribeContent
              className={className}
              wrapperClassName={wrapperClassName}
              eventLabel={eventLabel}
              onSubmitForm={handleSubmitForm}
              title={title}
              description={description}
              isColumnText={isColumnText}
              isModal
              successfulSubmissonFormAnalytics={
                successfulSubmissonFormAnalytics && {
                  formname: successfulSubmissonFormAnalytics.formname,
                  zoneclick: 'P',
                }
              }
            />
          </div>
        </PortalModal>
      )}
    </>
  );
}
