import { Typography } from '@snack-uikit/typography';
import cn from 'classnames';
import { ReactNode } from 'react';
import { Link } from 'src/components/Link';

import s from './OfferCard.module.scss';

interface OfferCardProps {
  link: string;
  title: string;
  icon?: string | ReactNode;
  onClickForClose(): void;
  theme?: string;
  target?: string;
}

export function OfferCard({
  link,
  title,
  icon,
  onClickForClose,
  theme,
  target,
}: OfferCardProps) {
  return (
    <Link
      style={theme ? { backgroundColor: theme } : {}}
      href={link}
      className={cn(s.linkCard)}
      onClick={onClickForClose}
      id={`header-linkcard-${title}`}
      target={target}
      analyticsOptions={{
        action: link,
        clickZone: 'header',
        clickElement: 'textclick',
        clickContent: title,
        uniqueId: `header-linkcard-link-${title}`,
        transitionType: 'inside-link',
      }}
    >
      <div className={s.linkCardDescription}>
        <Typography
          tag="h4"
          size="m"
          purpose="title"
          family="sans"
          className={s.linkCardTitle}
        >
          <span dangerouslySetInnerHTML={{ __html: title }} />
        </Typography>
      </div>
      {icon && (
        <div className={s.linkCardImageWrapper}>
          {typeof icon === 'string' ? (
            <img
              src={icon}
              width={88}
              height={68}
              alt={title}
              className={s.linkCardImage}
            />
          ) : (
            icon
          )}
        </div>
      )}
    </Link>
  );
}
